import React from "react"
import PropTypes from "prop-types"

import Background from "../components/Background"

const Layout = ({ children, image, color, location, imgStyle }) => {
  return (
    <Background
      image={image}
      color={color}
      location={location}
      imgStyle={imgStyle}
    >
      {children}
    </Background>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
