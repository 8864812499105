import React, { useContext } from "react"
import cn from "classnames"

import css from "./Background.module.css"
import Image from "../image"
import { MainContext } from "../../config/index"
import { useEffect } from "react"

function Background({ children, image, color, imgStyle, location = {} }) {
  const [context, setContext] = useContext(MainContext)

  useEffect(() => {
    if (
      location.state &&
      location.state.isBackFromBlob &&
      context.isCrazyAnimationHappening
    ) {
      setContext({ ...context, isCrazyAnimationHappening: false })
    }
  }, [location.state, context, setContext])

  return (
    <>
      <div
        className={cn(
          css.colorDiv,
          { "animation-div": context.isCrazyAnimationHappening },
          {
            "post-blob-animation":
              location.state && location.state.isBackFromBlob,
          }
        )}
        style={color ? { backgroundColor: color } : {}}
      >
        {image && (
          <Image
            className={css.image}
            src={image}
            style={{ position: "fixed" }}
            imgStyle={imgStyle || { objectPosition: "top center" }}
          />
        )}

        {children}
      </div>
      <div id="portal"></div>
    </>
  )
}

export default Background
